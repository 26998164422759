<template>
  <!-- 历史记录 -->
  <div id="app">
    <div class="container">
      <div class="list">
        <div class="search">
          <el-input placeholder="输入关键字进行搜索" v-model="filterText">
          </el-input>
        </div>
        <div class="tree">
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            default-expand-all
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="selectDevice"
          >
          </el-tree>
        </div>
      </div>
      <div class="content">
        <dv-border-box-4
          style="padding: 30px 0 0 30px; box-sizing: border-box"
          :color="['#135b85', '#0bced9']"
        >
          <div style="font-size: 21px; color: #1fc6a5">
            <i class="el-icon-s-claim"></i>
            {{ treeSelected.deviceId }}
          </div>
          <dv-decoration-1
            :color="['#067e82', '#067e82']"
            style="width: 400px; height: 20px"
          />
          <!-- 日期选择栏部分 -->
          <div>
            <el-row :gutter="10">
              <el-col :span="5.5">
                <el-date-picker
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  v-model="pickerValue"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="1.5"><el-button>查询</el-button></el-col>
              <el-col :span="4"><el-button>导出历史数据</el-button></el-col>
            </el-row>
          </div>
          <!-- 表格部分 -->
          <div class="table">
            <el-table :data="tableData" style="width: 100%" height="100%">
              <el-table-column label="序号" type="index" width="80" align="center" fixed>
              </el-table-column>
              <el-table-column label="IMEI" width="180" align="center" fixed>
              </el-table-column>
              <el-table-column label="日期" width="180" align="center" fixed>
              </el-table-column>
              <el-table-column label="用电量" width="120" align="center" fixed> </el-table-column>
              <el-table-column label="电表读数" width="120" align="center" fixed> </el-table-column>
              <el-table-column label="遍历列" align="center"> </el-table-column>
            </el-table>
          </div>
        </dv-border-box-4>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import DeviceHistoryValue from '../../../api/manageApi/DeviceHistoryValue';
export default {
  watch: {
    // 监听输入框的变化事件
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  components: {},
  props: {},
  created() {
    // 获取设备（树形图）数据
    this.getDeviceData()
  },
  data() {
    return {
      // 时间日期选择双向绑定
      pickerValue:["2023-05-01","2023-05-10"],
      // 搜索框双向绑定
      filterText: "",
      // 树形图绑定数据
      treeData: [],
      // 树形图绑定假字段
      defaultProps: {
        children: "children",
        label: "deviceId",
      },
      // 点击树形图取得的字段
      treeSelected:{
        deviceId:'',
        deviceName:''
      },
      // 表格绑定数据
      tableData: [],

    };
  },
  methods: {
    // 获取树形图数据
    async getDeviceData(){
      let deviceType = "DXElectricMeter"
      const res = await DeviceHistoryValue.getHierarchicalList(deviceType,"")
      console.log(res.data.data.list);
      this.treeData = res.data.data.list
      // 调用默认选择的方法
      this.defaultView()
    },
    // tree搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 点击树形事件
    selectDevice(val){
      this.treeSelected.deviceId = val.deviceId
    },
    // 设置进入页面之后的默认显示事件（作为获取数据的回调函数）
    defaultView(){
      // 将首项设为默认展示
      let defaultInfo = this.treeData[0]
      this.selectDevice(defaultInfo)
    }
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../../../assets/css/uiCss.scss";
@import "../../../assets/css/tableCss.scss";
#app {
  .container {
    padding-top:90px ;
    position: relative;
    width: 100%;
    height: 80vh;
    .list {
      position: absolute;
      left: 0;
      height: 100%;
      width: 15%;
      .search {
        padding-top: 15px;
      }
    }
    .content {
      position: absolute;
      right: 0;
      height: 100%;
      width: 85%;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .el-button {
      color: #033744;
      background-color: #397de2;
      border: none;
    }
  }
  .table {
    width: 99%;
    height: 89%;
    box-sizing: border-box;
  }
}
</style>